export default function useDiscoverMenu() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const localePath = useLocalePath();
  const { $pgettext } = useGettext();
  const { locale } = useI18n();

  /********************
   * REFS & VARS       *
   ********************/
  const showDiscoverMenu = computed(() => {
    return locale.value === 'de' || locale.value === 'en';
  });

  const discoverUrl = computed(() => {
    if (!showDiscoverMenu) {
      return localePath({ name: 'home', params: {} });
    }
    let path = 'discover';
    if (locale.value === 'de') {
      path = 'entdecken';
    }
    return localePath({ name: 'topic-all', params: { all: [path] } });
  });

  const discoverText = computed(() => {
    return $pgettext('Header Navigation Title', 'Discover');
  });

  return {
    showDiscoverMenu,
    discoverUrl,
    discoverText,
  };
}
