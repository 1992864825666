<script setup lang="ts">
// icons
import { faGlobeAmericas } from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiLanguageSwitchProps {
  iconRatio?: string;
  labelPosition?: 'left' | 'right';
  type?: 'white' | 'primary' | 'gray';
}
const props = withDefaults(defineProps<CiLanguageSwitchProps>(), {
  iconRatio: '1',
  labelPosition: 'right',
  type: 'gray',
});

/********************
 * COMPOSITIONS      *
 ********************/
const $attrs = useAttrs();
const appStore = useAppStore();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();
const router = useRouter();
const switchLocalePath = useSwitchLocalePath();
const { locales, locale } = useI18n();

/********************
 * REFS & VARS       *
 ********************/
const colorMapping = {
  primary: {
    select: 'text-primary bg-transparent',
    options: 'text-primary',
  },
  gray: {
    select: 'text-gray-light',
    options: 'text-gray-light',
  },
  white: {
    select: 'text-white',
    options: 'text-black',
  },
};

const iconClasses = computed(() => {
  switch (props.type) {
    case 'white':
      return 'fill-white';
    case 'primary':
      return 'fill-primary';
    default:
      return 'fill-gray';
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function onChange(event) {
  let targetUrl;

  if (isDynamicPage(getRouteBaseName(route))) {
    const alternateUrl = getDynamicAlternateForLang(route.fullPath, event.target.value, appStore.routes);
    if (alternateUrl) {
      targetUrl = alternateUrl;
    }
  } else {
    targetUrl = router
      .resolve(switchLocalePath(event.target.value))
      .href.replace(/^\/[a-z]{2}\//, `/${event.target.value}/`);
  }

  const alternateTag = document.querySelector(`link[rel=alternate][hreflang=${event.target.value}]`);

  if (alternateTag && alternateTag.href) {
    window.location.href = alternateTag.href.replace(/https?:\/\/[^/]+/, '');
  } else {
    window.location.href = targetUrl;
  }
}
</script>

<template>
  <div>
    <div class="relative flex w-full items-center">
      <select
        v-if="props.labelPosition === 'left'"
        :id="$attrs.name"
        class="absolute left-auto right-0 z-10 mr-2 h-8 w-auto max-w-40 appearance-none overflow-hidden truncate border-none bg-transparent pr-[2.2rem] text-left text-xs font-medium capitalize hover:cursor-pointer focus:outline-none"
        :class="colorMapping[props.type].select"
        style="text-align-last: right"
        @change="onChange($event)"
      >
        <option
          v-for="l in locales"
          :key="l.code"
          :selected="l.code === locale"
          :value="l.code"
          :class="colorMapping[props.type].options"
        >
          {{ l.name }}
        </option>
      </select>

      <label
        class="relative z-10 m-0"
        :for="$attrs.name"
      >
        <CiAwesomeIcon
          :class="iconClasses"
          :icon="faGlobeAmericas"
          :ratio="props.iconRatio"
        />
        <span class="sr-only">Language:</span>
      </label>

      <select
        v-if="props.labelPosition === 'right'"
        :id="$attrs.name"
        class="absolute appearance-none pl-[2.4rem]"
        :class="colorMapping[props.type].select"
        @change="onChange($event)"
      >
        <option
          v-for="l in locales"
          :key="l.code"
          :selected="l.code === locale"
          :value="l.code"
          :class="colorMapping[props.type].options"
        >
          {{ l.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<style></style>
