<script setup lang="ts">
/********************
 * FUNCTIONS         *
 ********************/
function onTouchmove(event: TouchEvent) {
  event.preventDefault();
  event.stopPropagation();
}
</script>

<template>
  <div
    class="content-[' '] fixed left-0 top-0 z-[99] block h-screen w-screen overflow-hidden bg-[rgb(0,0,0)]/70 transition-opacity duration-100 ease-in-out"
    @touchmove="onTouchmove"
  />
</template>
